.btnEmpezarAhora {
  background: var(--violet-dark-web-space);
  text-align: center;
  border-radius: 1.4rem;
  justify-content: center;
  align-items: center;
  width: 13.6rem;
  height: 4rem;
  font-size: 3.2rem;
  font-weight: 500;
  text-decoration: none;
  transition: all .1s ease-in;
  display: flex;
}

.btnEmpezarAhora p {
  color: #fff;
  font-family: Akzidenz-Grotesk Pro Med, sans-serif;
  font-size: 1.6rem;
  line-height: 4.042rem;
}

.btnEmpezarAhora2:active, .btnEmpezarAhora:active {
  transform: scale(1.05);
}

@media (width >= 1024px) {
  .btnEmpezarAhora {
    background: var(--violet-dark-web-space);
    border: .3rem solid var(--violet-dark-web-space);
    text-align: center;
    border-radius: 2rem;
    justify-content: center;
    align-items: center;
    width: 26.4rem;
    height: 7.2rem;
    font-weight: 500;
    transition: background .2s ease-in, color .2s ease-in, border-color .2s ease-in;
    display: flex;
  }

  .btnEmpezarAhora p {
    color: #fff;
    font-family: Akzidenz-Grotesk Pro Med, sans-serif;
    font-size: 3.2rem;
  }

  .btnEmpezarAhora:hover {
    background: var(--violet-dark-gradient-opacity10-web-space);
    color: var(--violet-dark-web-space);
    border-color: var(--violet-dark-web-space);
    cursor: pointer;
  }

  .btnEmpezarAhora:hover p {
    color: var(--violet-dark-web-space);
  }
}
/*# sourceMappingURL=index.7df56701.css.map */
