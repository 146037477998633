.btnEmpezarAhora {
	width: 13.6rem;
	height: 4rem;
	background: var(--violet-dark-web-space);
	border-radius: 1.4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 3.2rem;
	text-align: center;
	text-decoration: none;
	transition: all 0.1s ease-in;
}

.btnEmpezarAhora p {
	line-height: 4.042rem;
	font-size: 1.6rem;
	font-family: "Akzidenz-Grotesk Pro Med", sans-serif;
	color: #ffffff;
}

.btnEmpezarAhora2:active {
	transform: scale(1.05);
}

.btnEmpezarAhora:active {
	transform: scale(1.05);
}

@media (min-width: 1024px) {
	.btnEmpezarAhora {
		width: 26.4rem;
		height: 7.2rem;
		background: var(--violet-dark-web-space);
		border: 0.3rem solid var(--violet-dark-web-space);
		border-radius: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: background 0.2s ease-in, color 0.2s ease-in,
			border-color 0.2s ease-in;
		font-weight: 500;
		text-align: center;
	}

	.btnEmpezarAhora p {
		font-family: "Akzidenz-Grotesk Pro Med", sans-serif;
		font-size: 3.2rem;
		color: #ffffff;
	}

	.btnEmpezarAhora:hover {
		background: var(--violet-dark-gradient-opacity10-web-space);
		color: var(--violet-dark-web-space);
		border-color: var(--violet-dark-web-space);
		cursor: pointer;
	}

	.btnEmpezarAhora:hover p {
		color: var(--violet-dark-web-space);
	}
}
